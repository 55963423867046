import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Image from '../elements/Image';
import SectionHeader from './partials/SectionHeader';
import './override.css'

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const mentorIntro = {
    title: '',
    paragraph: 'The mentor is a Reiki Master, Practioner, Numerologist and expert in Vastu and Crystals. '
  };
  const about = {
    title: 'About VRekindle',
    paragraph: 'VRekindle is devoted towards helping people by educating them in the fields of alternate sciences such as Reiki, Numerology, Vastu, Crystals and more. We offer consultancy as well as courses for this purpose.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              Rekindle Your Life
              <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                Learn Reiki and Numerology
              </div>
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                Welcome to the Reiki World
              </p>
              <p className='no-margin-bottom'>WITH</p>
            </div>
          </div>
          <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
            <h1 className=" mb-16 reveal-from-bottom" data-reveal-delay="200">
              <span className="text-color-primary">Mentor</span> Neeraj Kullshrreshtha
            </h1>
            <a
              data-video="https://player.vimeo.com/video/174002812"
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            >
              <Image
                className="has-shadow"
                src={require('./../../assets/images/neeraj.jpeg')}
                alt="Hero"
                width={500} />
            </a>
          </div>

        </div>
        <SectionHeader className="no-margin-bottom no-margin-top" data={mentorIntro} className="center-content" />
        <SectionHeader data={about} className="center-content" />
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;